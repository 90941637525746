import styled from 'styled-components';

import { fonts } from '@/theme';

export const Wrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 35px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

export const Text = styled.p`
  ${fonts.text}
  max-width: 800px;
  @media (max-width: 991px) {
    ${fonts.smallText22}
    max-width: 100%;
  }
`;
