import React, { useCallback } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

import { useObserver } from '@/hooks';
import Button from '@/components/Button';
import { FullScreenWrapper } from '../styles';

import { Wrapper, Text } from './styles';

const Thanks = ({ pageContext }) => {
  const { setPosition } = useObserver();

  const redirectToMain = useCallback(() => navigate('/'), []);

  return (
    <>
      <Wrapper onTouchStart={setPosition} onMouseEnter={setPosition}>
        <Text>{pageContext.text}</Text>
      </Wrapper>
      <FullScreenWrapper>
        <Button type="button" onClick={redirectToMain} onTouchStart={setPosition} onMouseEnter={setPosition} primary>
          Zur Startseite
        </Button>
      </FullScreenWrapper>
    </>
  );
};

Thanks.propTypes = {
  pageContext: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default Thanks;
export { default as Head } from '@/components/Head';
